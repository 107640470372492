import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tod',
  templateUrl: './tod.component.html',
  styleUrls: ['./tod.component.scss']
})
export class TodComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
