import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-covidresult',
  templateUrl: './covidresult.component.html',
  styleUrls: ['./covidresult.component.css']
})
export class CovidresultComponent implements OnInit {

  public totalCovid : any = [];
  public covidres : any;
  constructor() { }

  ngOnInit() {
    this.totalCovid = localStorage.getItem('covidresult');
    this.covidres = this.totalCovid;
    console.log(this.totalCovid);
  }

}
