import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ctaboxfour',
  templateUrl: './ctaboxfour.component.html',
  styleUrls: ['./ctaboxfour.component.scss']
})
export class CtaboxfourComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
