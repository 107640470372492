import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reg-bhmsdemo-sft',
  templateUrl: './reg-bhmsdemo-sft.component.html',
  styleUrls: ['./reg-bhmsdemo-sft.component.scss']
})
export class RegBHMSdemoSftComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
