import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-staff-birthday',
  templateUrl: './staff-birthday.component.html',
  styleUrls: ['./staff-birthday.component.scss']
})
export class StaffBirthdayComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
