import { Component, OnInit } from '@angular/core';
import { career } from './career.model';
import { AppService } from '../app.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss']
})
export class CareerComponent implements OnInit {

  constructor(private _AppService: AppService, private router: Router) { }

  model = new career();
  ReadImageURL: boolean = false;
  ImageUrl: string = "";
  fileList1: any;
  submit:boolean=true;

  ngOnInit() {
  }

  OpenFileBrowser(event: any) {
    let element: HTMLElement = document.getElementById('OpnDialog') as HTMLElement;
    element.click();
  }

  readUrl(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.ImageUrl = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
      this.ReadImageURL = true;
    }
    let fileList: FileList = event.target.files;
    this.fileList1 = fileList
  }

  SaveResume(valid: boolean) {
    if (valid) {
      this.submit=false;
      if (this._AppService.validData(this.fileList1)) {
        if (this.fileList1.length > 0) {
          let file: File = this.fileList1[0];
          console.log(file);
          let formData: FormData = new FormData();
          formData.append('uploadFile', file, file.name);
          console.log(formData);
          this._AppService.post("api/SendMail/CareerUploadResume", formData)
            .subscribe(res => {
              if (res != null) {
                this.model.ResumePath = res;
                this.SubmitData();
              }
            })
        }
      }
      else {
        this.submit=true;
        alert('Please upload your resume..!!');
      }
    }
  }

  SubmitData(): void {
    this._AppService.post('api/SendMail/Career', this.model).subscribe(
      res => {
        if (res.toString().toUpperCase() == "SUCCESS") {
          alert('Thank You for your response.\nWe will revert back to you  soon.');
          this.router.navigateByUrl('/index.html');
        }
        else {
          alert(res);
          this.router.navigateByUrl('/index.html');
        }
      }
    );
  }

}

