import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ppc-seo',
  templateUrl: './ppc-seo.component.html',
  styleUrls: ['./ppc-seo.component.scss']
})
export class PpcSeoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
