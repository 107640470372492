import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-foxpro-to-asp-dotnet',
  templateUrl: './foxpro-to-asp-dotnet.component.html',
  styleUrls: ['./foxpro-to-asp-dotnet.component.scss']
})
export class FoxproToAspDotnetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
