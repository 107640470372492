export class Support{
    Name: any;
    Email: any;
    Mobile: any;
    Organization:any;
    Product: any;
    ProblemDesc: any;
    Priority:any;
    Problem:any;
    ImagePath:any;
}