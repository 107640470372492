import { Component, OnInit } from '@angular/core';
import { enquiry } from './enquiry.model';
import { AppService } from '../app.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.css']
})
export class EnquiryComponent implements OnInit {
  constructor(private service: AppService) { }

  model = new enquiry();
  submit:boolean=true;
  ngOnInit() {
  }

  SubmitData(form:NgForm): void {
    if (form.valid) {
      this.submit=false;
      this.service.post('api/SendMail/Enquiry', this.model).subscribe(
        res => {
          if (res.toString().toUpperCase() == "SUCCESS") {
            alert('Thank You for your response.\nWe will revert back to you  soon.');
            document.getElementById('id01').style.display = 'none';
            form.reset();
          }
          else {
            alert(res);
          }
        }
      );
    }
  }

}
