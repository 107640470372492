import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exporter-calculator',
  templateUrl: './exporter-calculator.component.html',
  styleUrls: ['./exporter-calculator.component.scss']
})
export class ExporterCalculatorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
