import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contab',
  templateUrl: './contab.component.html',
  styleUrls: ['./contab.component.css']
})
export class ContabComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
