import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ivp',
  templateUrl: './ivp.component.html',
  styleUrls: ['./ivp.component.scss']
})
export class IvpComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
