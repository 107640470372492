import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes} from '@angular/router';
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy} from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpModule} from '@angular/http';
import { AppService} from './app.service';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SliderComponent } from './slider/slider.component';
import { ServicesComponent } from './services/services.component';
import { HomeComponent } from './home/home.component';
import { EmsComponent } from './ems/ems.component';
import { ProductsComponent } from './products/products.component';
import { AchievmentsComponent } from './achievments/achievments.component';
import { CtaboxComponent } from './ctabox/ctabox.component';
import { CustomersComponent } from './customers/customers.component';
import { CtaboxtwoComponent } from './ctaboxtwo/ctaboxtwo.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { VerticalsComponent } from './verticals/verticals.component';
import { TechnologiesComponent } from './technologies/technologies.component';
import { PartnersComponent } from './partners/partners.component';
import { WhychooseComponent } from './whychoose/whychoose.component';
import { AboutComponent } from './about/about.component';
import { ClientsComponent } from './clients/clients.component';
import { ContactComponent } from './contact/contact.component';
import { CareerComponent } from './career/career.component';
import { PmsComponent } from 'src/app/pms/pms.component';
import { ErpComponent } from './erp/erp.component';
import { EximComponent } from './exim/exim.component';
import { BhmsComponent } from './bhms/bhms.component';
import { CsrComponent } from './csr/csr.component';
import { SoftwareDevelopmentComponent } from './software-development/software-development.component';
import { FoxproMigrationComponent } from './foxpro-migration/foxpro-migration.component';
import { FoxproToAspDotnetComponent } from './foxpro-to-asp-dotnet/foxpro-to-asp-dotnet.component';
import { ApplicationMigrationComponent } from './application-migration/application-migration.component';
import { SiDownloadSoftwareComponent } from './si-download-software/si-download-software.component';
import { CelebrationComponent } from './celebration/celebration.component';
import { CaseStudyComponent } from './case-study/case-study.component';
import { TodComponent } from './tod/tod.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { WebsiteDevelopmentComponent } from './website-development/website-development.component';
import { EcommerceDevelopmentComponent } from './ecommerce-development/ecommerce-development.component';
import { CmsDevelopmentComponent } from './cms-development/cms-development.component';
import { SeoComponent } from './seo/seo.component';
import { AppDevelopmentComponent } from './app-development/app-development.component';
import { DigitalMarketingComponent } from './digital-marketing/digital-marketing.component';
import { CloudSolutionComponent } from './cloud-solution/cloud-solution.component';
import { IntroComponent } from './intro/intro.component';
import { NewdesignComponent } from './newdesign/newdesign.component';
import { NewheaderComponent } from './newheader/newheader.component';
import { PmpComponent } from './pmp/pmp.component';
import { IvpComponent } from './ivp/ivp.component';
import { ProductComponent } from './product/product.component';
// import { AirtelComponent } from './airtel/airtel.component';
import { BarcodescsolutionComponent } from './barcodescsolution/barcodescsolution.component';
import { CmsComponent } from './cms/cms.component';
import { ContabComponent } from './contab/contab.component';
import { CurrconversionComponent } from './currconversion/currconversion.component';
import { DirectorysubmissionComponent } from './directorysubmission/directorysubmission.component';
import { DomainRegistrationComponent } from './domain-registration/domain-registration.component';
import { DownloadComponent } from './download/download.component';
import { DownloadSoftwareComponent } from './download-software/download-software.component';
import { ClientOpinionComponent } from './client-opinion/client-opinion.component';
import { EventsComponent } from './events/events.component';
import { EximBrochureComponent } from './exim-brochure/exim-brochure.component';
import { ExporterCalculatorComponent } from './exporter-calculator/exporter-calculator.component';
import { IncreaseNetTrfComponent } from './increase-net-trf/increase-net-trf.component';
import { IndustrialTrainingComponent } from './industrial-training/industrial-training.component';
import { InspirationalThoughtsComponent } from './inspirational-thoughts/inspirational-thoughts.component';
import { InternetMarketingComponent } from './internet-marketing/internet-marketing.component';
import { InternshipComponent } from './internship/internship.component';
import { JewelleryFairComponent } from './jewellery-fair/jewellery-fair.component';
import { BlankComponent } from './blank/blank.component';
import { LinkPopdevelopmentComponent } from './link-popdevelopment/link-popdevelopment.component';
import { MobileAppsComponent } from './mobile-apps/mobile-apps.component';
import { OnlineSupportComponent } from './online-support/online-support.component';
import { PaymentGatewayintComponent } from './payment-gatewayint/payment-gatewayint.component';
import { PpcSeoComponent } from './ppc-seo/ppc-seo.component';
// import { RegThanksComponent } from './reg-thanks/reg-thanks.component';
import { SesubmissionComponent } from './sesubmission/sesubmission.component';
import { SremoteSupportComponent } from './sremote-support/sremote-support.component';
import { SrsDisclaimerComponent } from './srs-disclaimer/srs-disclaimer.component';
import { SocialObligationsComponent } from './social-obligations/social-obligations.component';
import { SremoteSupport1Component } from './sremote-support1/sremote-support1.component';
import { DownloadDthComponent } from './download-dth/download-dth.component';
import { SysAnlConsultancyComponent } from './sys-anl-consultancy/sys-anl-consultancy.component';
import { EventManagementComponent } from './event-management/event-management.component';
import { EventManagement2Component } from './event-management2/event-management2.component';
import { EventMgmtCompComponent } from './event-mgmt-comp/event-mgmt-comp.component';
import { SupportComponent } from './support/support.component';
import { AutodemoRegComponent } from './autodemo-reg/autodemo-reg.component';
import { PmsBrochureComponent } from './pms-brochure/pms-brochure.component';
import { RegBHMSdemoSftComponent } from './reg-bhmsdemo-sft/reg-bhmsdemo-sft.component';
import { RegBHMSdemoComponent } from './reg-bhmsdemo/reg-bhmsdemo.component';
import { RegEXIMdemoComponent } from './reg-eximdemo/reg-eximdemo.component';
import { RegPMSdemoComponent } from './reg-pmsdemo/reg-pmsdemo.component';
import { EnquiryComponent } from './enquiry/enquiry.component';
import { CtaboxthreeComponent } from './ctaboxthree/ctaboxthree.component';
import { CtaboxfourComponent } from './ctaboxfour/ctaboxfour.component';
import { LoginComponent } from './login/login.component';
import { MissionComponent } from './mission/mission.component';
import { VisionComponent } from './vision/vision.component';
// import { DigitalSigningSolutionComponent } from './digital-signing-solution/digital-signing-solution.component';
// import { DiwaliCelebrationComponent } from './diwali-celebration/diwali-celebration.component';
import { DiwaliNinteenComponent } from './diwali-ninteen/diwali-ninteen.component';
import { CompanyAnniversaryComponent } from './office-anniversary/company-anniversary.component';
import { StaffBirthdayComponent } from './staff-birthday/staff-birthday.component';
import { OfficePicnicComponent } from './office-picnic/office-picnic.component';
import { FairParticipationComponent } from './fair-participation/fair-participation.component';
import { ArchieveComponent } from './archieve/archieve.component';
import { IndependenceDayComponent } from './independence-day/independence-day.component';
import { NewsArticleComponent } from './news-article/news-article.component';
import { CertificationComponent } from './certification/certification.component';
import { CovidtestComponent } from './covidtest/covidtest.component';
import { CovidresultComponent } from './covidresult/covidresult.component';
import { SmartComponent } from './smart/smart.component';
import { TrademarkComponent } from './trademark/trademark.component';
import { CopyrightComponent } from './copyright/copyright.component';
import { OfficeComponent } from './office/office.component';
import { PicnicsComponent } from './picnics/picnics.component';


const routes: Routes = [
  {path: '', component: HomeComponent,},
      {path: 'index.html', component: HomeComponent},
      {path: 'covidtest.html', component: CovidtestComponent},
      {path: 'covidresult.html', component: CovidresultComponent},
      {path: 'aboutus.html', component: AboutComponent},
      {path: 'clients.html', component: ClientsComponent},
      {path: 'contactus.html', component: ContactComponent},
      {path: 'career.html', component: CareerComponent},
      {path: 'newdesign.html', component: NewdesignComponent},
      {path: 'export-documentation-management-software.html', component: EmsComponent},
      // {path: 'photo-offer-catalog-software.html', component: PmsComponent},
      {path: 'trademark.html', component: TrademarkComponent},
      {path: 'copyright.html', component: CopyrightComponent},
      {path: 'erp-export-software.html', component: ErpComponent},
      {path: 'export-import-management-software.html', component: EximComponent},
      {path: 'buying-house-agent-management-software.html', component: BhmsComponent},
      {path: 'software-development.html', component: SoftwareDevelopmentComponent},
      {path: 'e-commerce-solutions.html', component: EcommerceDevelopmentComponent},
      {path: 'search-engine-optimization.html', component: SeoComponent},
      {path: 'website-development-company-delhi.html', component: WebsiteDevelopmentComponent},
      {path: 'app-development-company-delhi.html', component: AppDevelopmentComponent},
      {path: 'digital-marketing-company-delhi.html', component: DigitalMarketingComponent},
      {path: 'cms-development-delhi.html', component: CmsDevelopmentComponent},
      {path: 'cloud-solutions.html', component: CloudSolutionComponent},
      {path: 'visual-foxpro-vfp-migration-to-asp.html', component: FoxproMigrationComponent},
      {path: 'visual-foxpro-to-asp-net.html', component: FoxproToAspDotnetComponent},
      {path: 'application-migration.html', component: ApplicationMigrationComponent},
      {path: 'shiva_infotech_download_software.html', component: SiDownloadSoftwareComponent},
      {path: 'social-obligations.html', component: CsrComponent},
      {path: 'celebrating_silver_jubilee.html', component: CelebrationComponent},
      {path: 'case-study.html', component: CaseStudyComponent},
      {path: 'privacy&policy.html', component: PrivacyComponent},
      {path: 'termsofuse.html', component: TermsComponent},
      {path: 'eturbo-PMP-Project-Management-Portal.html', component: PmpComponent},
      {path: 'eturbo-ivp-invoice-validation-portal.html', component: IvpComponent},
      {path: 'testimonial.html', component: TestimonialsComponent},
      {path: 'product.html', component: ProductComponent},
      // {path: 'airtel.html', component: AirtelComponent},
      {path: 'barcoding-and-scanning-solutions.html', component: BarcodescsolutionComponent},
      {path: 'content-management-system.html', component: CmsComponent},
      {path: 'ConTab.html', component: ContabComponent},
      {path: 'currency-conversion.html', component: CurrconversionComponent},     
      {path: 'download.html', component: DownloadComponent}, 
      {path: 'download-softwares.html', component: DownloadSoftwareComponent},
      {path: 'clients-opinions.html', component: ClientOpinionComponent},
      {path: 'directory-submission.html', component: DirectorysubmissionComponent},
      {path: 'domain-registration.html', component: DomainRegistrationComponent},
      {path: 'events.html', component: EventsComponent},
      {path: 'EXIM-Brochure.html', component: EximBrochureComponent},
      {path: 'exporter-calculator.html', component: ExporterCalculatorComponent},
      {path: 'increase-net-traffic.html', component: IncreaseNetTrfComponent},
      {path: 'industrial_training.html', component: IndustrialTrainingComponent},
      {path: 'inspirational_thoughts.html', component: InspirationalThoughtsComponent},
      {path: 'internet-marketing.html', component: InternetMarketingComponent},
      {path: 'Internship.html', component: InternshipComponent},
      {path: 'Jewellery_fair.html', component: JewelleryFairComponent},
      {path: 'link-popularity-development.html', component: LinkPopdevelopmentComponent},
      {path: 'mobile_apps.html', component: MobileAppsComponent},
      {path: 'online-support.aspx', component: OnlineSupportComponent},
      {path: 'payment-gateway-integration.html', component: PaymentGatewayintComponent},
      {path: 'pay-per-click-ppc-seo.html', component: PpcSeoComponent},
      // {path: 'Reg_Thanks.htm', component: RegThanksComponent},
      {path: 'search-engine-submission.html', component: SesubmissionComponent},
      {path: 'shiva_remote_support.html', component: SremoteSupportComponent},
      {path: 'social-obligations2.html', component: SocialObligationsComponent},
      {path: 'shiva_remote_support1.html', component: SremoteSupport1Component},
      {path: 'system_analysis_and_consultancy.html', component: SysAnlConsultancyComponent},
      {path: 'solutions-online_digital_signature_certificate_software_tool_download-DTH.html', component: DownloadDthComponent},
      {path: 'event-management.html', component: EventManagementComponent},
      {path: 'event-management2.html', component: EventManagement2Component},
      {path: 'event_management_Computerized_Visitors_Registration_at_Exhibitions_with_Barcode_scanning.html', component: EventMgmtCompComponent},
      {path: 'support.html', component: SupportComponent},
      {path: 'products.html', component: ProductsComponent},
      {path: 'pms_auto_demo_registration.html', component: AutodemoRegComponent},
      {path: 'PMS-Brochure.html', component: PmsBrochureComponent},
      {path: 'registration-form-for-BHMS-demo-softwares-form.html', component: RegBHMSdemoSftComponent},
      {path: 'registration-form-for-BHMS-demo-softwares.html', component: RegBHMSdemoComponent},
      {path: 'registration-form-for-EXIM-demo-softwares.html', component: RegEXIMdemoComponent},
      {path: 'registration-form-for-PMS-demo-softwares.html', component: RegPMSdemoComponent},
      {path: 'mission.html', component: MissionComponent},
      {path: 'vision.html', component: VisionComponent},
      {path: 'login.html', component: LoginComponent},
      // {path: 'digital-signing-solution.html', component: DigitalSigningSolutionComponent},
     // {path: 'diwali-celebration.html', component: DiwaliCelebrationComponent},
      {path: 'diwali-ninteen.html', component: DiwaliNinteenComponent},
      {path: 'company-anniversary.html', component: CompanyAnniversaryComponent},
      {path: 'staff-birthday.html', component: StaffBirthdayComponent},
      {path: 'office-picnic.html', component: OfficePicnicComponent},
      {path: 'fair-participation.html', component: FairParticipationComponent},
      {path:  'picnics.html', component: PicnicsComponent},
      {path: 'archieve.html', component: ArchieveComponent},
      {path: 'news-article.html', component: NewsArticleComponent},
      {path: 'certification.html', component: CertificationComponent},
      {path: 'independence-day.html', component: IndependenceDayComponent},
      {path: 'smart.html', component: SmartComponent},
      {path: '**', redirectTo: 'index.html'},
      

];

@NgModule({
  declarations: [
    AppComponent, HeaderComponent, FooterComponent, SliderComponent,  ServicesComponent, HomeComponent, EmsComponent, ProductsComponent,
    AchievmentsComponent, CtaboxComponent, CustomersComponent, CtaboxtwoComponent, TestimonialsComponent, VerticalsComponent,
    TechnologiesComponent, PartnersComponent, WhychooseComponent, AboutComponent, ClientsComponent, ContactComponent, CareerComponent,
    PmsComponent, ErpComponent,  EximComponent, BhmsComponent, CsrComponent, SoftwareDevelopmentComponent, FoxproMigrationComponent,
    FoxproToAspDotnetComponent, ApplicationMigrationComponent, CelebrationComponent, CaseStudyComponent, TodComponent, PrivacyComponent,
    TermsComponent, WebsiteDevelopmentComponent,  EcommerceDevelopmentComponent, CmsDevelopmentComponent, SeoComponent, AppDevelopmentComponent,
    DigitalMarketingComponent,  CloudSolutionComponent, IntroComponent, NewdesignComponent, NewheaderComponent, PmpComponent, IvpComponent, 
    ProductComponent,  BarcodescsolutionComponent, CmsComponent, ContabComponent, CurrconversionComponent, 
    DirectorysubmissionComponent, DomainRegistrationComponent, DownloadComponent, DownloadSoftwareComponent, SiDownloadSoftwareComponent, 
    ClientOpinionComponent, EventsComponent, EximBrochureComponent, ExporterCalculatorComponent, 
    IncreaseNetTrfComponent, IndustrialTrainingComponent, InspirationalThoughtsComponent, 
    InternetMarketingComponent, InternshipComponent, JewelleryFairComponent, BlankComponent, LinkPopdevelopmentComponent, 
    MobileAppsComponent, OnlineSupportComponent, PaymentGatewayintComponent, PpcSeoComponent, 
    SesubmissionComponent, SremoteSupportComponent, SrsDisclaimerComponent, SocialObligationsComponent, 
    SremoteSupport1Component, DownloadDthComponent, SysAnlConsultancyComponent, EventManagementComponent, 
    EventManagement2Component, EventMgmtCompComponent, SupportComponent, AutodemoRegComponent, PmsBrochureComponent, 
    RegBHMSdemoSftComponent, RegBHMSdemoComponent, RegEXIMdemoComponent, RegPMSdemoComponent, EnquiryComponent, 
    CtaboxthreeComponent, CtaboxfourComponent, LoginComponent, MissionComponent, VisionComponent,  
     DiwaliNinteenComponent, CompanyAnniversaryComponent, StaffBirthdayComponent, 
    OfficePicnicComponent, FairParticipationComponent, ArchieveComponent, IndependenceDayComponent, 
    NewsArticleComponent, CertificationComponent, CovidtestComponent, CovidresultComponent, SmartComponent, TrademarkComponent, CopyrightComponent, OfficeComponent, PicnicsComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    FormsModule,
    HttpModule
  ],


  exports: [RouterModule],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],

  bootstrap: [AppComponent]
})
export class AppModule {
  
 }

