import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: []
})
export class BlankComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  
  onActivate(event) {
    window.scroll(0,0);

    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)

  }

}
