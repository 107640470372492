import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jewellery-fair',
  templateUrl: './jewellery-fair.component.html',
  styleUrls: ['./jewellery-fair.component.scss']
})
export class JewelleryFairComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
