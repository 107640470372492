import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Http, Response } from '@angular/http';
import { Observable,throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private _http: Http) { }
   //public rootApiUrl: string = "http://localhost:58529/";
   //public rootApiUrl: string = "http://192.168.0.119/ShivaWebsite/API/";
   public rootApiUrl: string = "http://182.18.129.63/SIWebsiteAPI/";



  post(url: string, model): Observable<any> {
    return this._http.post(this.rootApiUrl + url, model)
      .pipe(map((response:Response)=> <any> response.json()),
      catchError(this.handleError))
  }

  private handleError(error: Response) {
    console.error(error);
    return Observable.throw(error.json().error || 'Server error');
  }
  // handleError(error) {
  //   let errorMessage = '';
  //   if (error.error instanceof ErrorEvent) {
  //     errorMessage = `Error: ${error.error.message}`;
  //   } else {
  //     errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  //   }
  //   return throwError(errorMessage);
  // }

  validData(data): boolean {
    if (data != "" && data != null && data != undefined) {
      return true
    }
    return false
  }
}
