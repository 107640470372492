import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ctabox',
  templateUrl: './ctabox.component.html',
  styleUrls: ['./ctabox.component.scss']
})
export class CtaboxComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
