import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-diwali-ninteen',
  templateUrl: './diwali-ninteen.component.html',
  styleUrls: ['./diwali-ninteen.component.scss']
})
export class DiwaliNinteenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
