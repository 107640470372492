import { Component, OnInit } from '@angular/core';
// import { ScrollTopService } from '../scrolltop.service';

@Component({
  selector: 'app-website-development',
  templateUrl: './website-development.component.html',
  styleUrls: ['./website-development.component.scss']
})
export class WebsiteDevelopmentComponent implements OnInit {
  ScrollTopService: any;

  constructor() { }

  ngOnInit() {
    // this.ScrollTopService.setScrollTop();
  }

}
