import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-link-popdevelopment',
  templateUrl: './link-popdevelopment.component.html',
  styleUrls: ['./link-popdevelopment.component.scss']
})
export class LinkPopdevelopmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
