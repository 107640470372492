import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-srs-disclaimer',
  templateUrl: './srs-disclaimer.component.html',
  styleUrls: ['./srs-disclaimer.component.css']
})
export class SrsDisclaimerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
