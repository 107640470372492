import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pms-brochure',
  templateUrl: './pms-brochure.component.html',
  styleUrls: ['./pms-brochure.component.scss']
})
export class PmsBrochureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
