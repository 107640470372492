import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-office-picnic',
  templateUrl: './office-picnic.component.html',
  styleUrls: ['./office-picnic.component.scss']
})
export class OfficePicnicComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
