import { Component, OnInit } from '@angular/core';
import { Support } from './Support.model';
import { AppService } from '../app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  model = new Support();
  ReadImageURL: boolean = false;
  ImageUrl: string = "";
  fileList1: any;
  submit:boolean=true;
  constructor(private _service: AppService, private router: Router) { }

  ngOnInit() {
  }

  OpenFileBrowser(event: any) {
    let element: HTMLElement = document.getElementById('OpnDialog') as HTMLElement;
    element.click();
  }

  readUrl(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.ImageUrl = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
      this.ReadImageURL = true;
    }
    let fileList: FileList = event.target.files;
    this.fileList1 = fileList
  }

  SaveImage(valid: boolean) {
    if (valid) {
      this.submit=false;
      if (this._service.validData(this.fileList1)) {
        if (this.fileList1.length > 0) {
          let file: File = this.fileList1[0];
          console.log(file);
          let formData: FormData = new FormData();
          formData.append('uploadFile', file, file.name);
          console.log(formData);
          this._service.post("api/SendMail/SupportUploadImage", formData)
            .subscribe(res => {
              if (res != null) {
                this.model.ImagePath = res;
                this.SubmitData(valid);
              }
            })
        }
      }
      else {
        this.model.ImagePath = "";
        this.SubmitData(valid);
      }
    }
  }

  SubmitData(valid: boolean) {
    if (valid) {
      this._service.post('api/SendMail/Support', this.model).subscribe(
        res => {
          if (res.toString().toUpperCase() == "SUCCESS") {
            alert('Thank You for your response.\nWe will revert back to you  soon.');
            this.router.navigateByUrl('/index.html');
          }
          else {
            alert(res);
            this.router.navigateByUrl('/index.html');
          }
        }
      );
    }
  }
}
