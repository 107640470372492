import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-management2',
  templateUrl: './event-management2.component.html',
  styleUrls: ['./event-management2.component.scss']
})
export class EventManagement2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
