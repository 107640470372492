import { Component, OnInit } from '@angular/core';
// import { ModalService } from '../modalservice';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  private bodyText: string;

  constructor() { }
  // constructor(private modalService: ModalService) { }

  
  ngOnInit() {
    this.bodyText = 'This text can be updated in modal 1';
}

// openModal(id: string) {
//     this.modalService.open(id);
// }

// closeModal(id: string) {
//     this.modalService.close(id);
// }

}
