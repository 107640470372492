import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fair-participation',
  templateUrl: './fair-participation.component.html',
  styleUrls: ['./fair-participation.component.scss']
})
export class FairParticipationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
