import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-anniversary',
  templateUrl: './company-anniversary.component.html',
  styleUrls: ['./company-anniversary.component.scss']
})
export class CompanyAnniversaryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
