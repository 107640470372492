export class career{
    Title:string;
    Name:string;
    Mobile:string;
    PostApplied:string;
    SoftSkill:string;
    Email:string;
    City:string;
    Education:string;
    Experience:string;
    ResumePath : string;
}