import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exim',
  templateUrl: './exim.component.html',
  styleUrls: ['./exim.component.scss']
})
export class EximComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
