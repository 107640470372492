import { Component, OnInit } from '@angular/core';
// import { Http } from '@angular/http';
import { contact } from './contact.model';
import { AppService } from '../app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  modal = new contact();
  submit:boolean=true;
  constructor(private service: AppService, private router: Router) { }

  ngOnInit() {

  }
  SubmitData(valid: boolean) {
    if (valid) {
      this.submit=false;
      this.service.post('api/SendMail/Contact', this.modal).subscribe(
        res => {
          if (res.toString().toUpperCase() == "SUCCESS") {
            alert('Thank You for your response.\nWe will revert back to you  soon.');
            this.router.navigateByUrl('/index.html');
          }
          else {
            alert(res);
            this.router.navigateByUrl('/index.html');
          }
        }
      );
    }
  }

  GetSelectedValue(id): void {
    // if (id == undefined) {
    //   this.SelectedValue = "SERVICES"
    // }
   
  }

}
