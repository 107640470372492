import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-opinion',
  templateUrl: './client-opinion.component.html',
  styleUrls: ['./client-opinion.component.scss']
})
export class ClientOpinionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
