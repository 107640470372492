import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-internet-marketing',
  templateUrl: './internet-marketing.component.html',
  styleUrls: ['./internet-marketing.component.scss']
})
export class InternetMarketingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
