import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cloud-solution',
  templateUrl: './cloud-solution.component.html',
  styleUrls: ['./cloud-solution.component.scss']
})
export class CloudSolutionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
