import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bhms',
  templateUrl: './bhms.component.html',
  styleUrls: ['./bhms.component.scss']
})
export class BhmsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
