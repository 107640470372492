import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-covidtest',
  templateUrl: './covidtest.component.html',
  styleUrls: ['./covidtest.component.css']
})
export class CovidtestComponent implements OnInit {

  opt1: number = 1
  opt2: number = 1
  opt3: number = 1
  opt4: number = 1
  opt5: number = 1
  opt6: number = 1
  opt7: number = 1
  opt8: number = 1
  opt9: number = 1
  opt10: number = 1
  opt11: number = 1
  opt12: number = 1
  selectedVal: number = 0
  totalNo:any
  grandTotal :number =0

  constructor(public router: Router) { }

  ngOnInit() {
  }
  saveData(){
    this.totalNo = this.opt1*1 + this.opt2*1 + this.opt3*1+this.opt4*1+this.opt5*1+this.opt6*1+this.opt7*1+this.opt8*1+this.opt9*1+this.opt10*1+ this.opt11*1 +this.opt12*1
    localStorage.setItem('covidresult', this.totalNo);
    console.log(this.totalNo);
    // alert(localStorage.getItem('covidresult'));
    this.router.navigateByUrl('/covidresult.html')
  }

  op1(event:any){
    this.opt1 = event.target.value
  }
  op2(event:any){
    this.opt2 = event.target.value
  }
  op3(event:any){
    this.opt3 = event.target.value
  }
  op4(event:any){
    this.opt4 = event.target.value
  }
  op5(event:any){
    this.opt5 = event.target.value
  }
  op6(event:any){
    this.opt6 = event.target.value
  }
  op7(event:any){
    this.opt7 = event.target.value
  }
  op8(event:any){
    this.opt8 = event.target.value
  }
  op9(event:any){
    this.opt9 = event.target.value
  }
  op10(event:any){
    this.opt10 = event.target.value
  }
  op11(event:any){
    this.opt11 = event.target.value
  }
  op12(event:any){
    this.opt12 = event.target.value
  }

}
