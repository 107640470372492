export class contact {
    Name: any;
    Email: any;
    Mobile: any;
    CompanyName: any;
    Designation: any;
    Website: any;
    Address: any;
    City: any;
    Country: any="Select";
    Source: any="Select";
    DealingProduct: any;
    Comments: any;
}