import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sesubmission',
  templateUrl: './sesubmission.component.html',
  styleUrls: ['./sesubmission.component.scss']
})
export class SesubmissionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
