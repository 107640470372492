import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-increase-net-trf',
  templateUrl: './increase-net-trf.component.html',
  styleUrls: ['./increase-net-trf.component.scss']
})
export class IncreaseNetTrfComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
