import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-obligations',
  templateUrl: './social-obligations.component.html',
  styleUrls: ['./social-obligations.component.scss']
})
export class SocialObligationsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
