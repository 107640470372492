import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pmp',
  templateUrl: './pmp.component.html',
  styleUrls: ['./pmp.component.scss']
})
export class PmpComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
