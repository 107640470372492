import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exim-brochure',
  templateUrl: './exim-brochure.component.html',
  styleUrls: ['./exim-brochure.component.scss']
})
export class EximBrochureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
